import React, { useCallback, useEffect, useState } from 'react'
import { IListBillRequest } from '../service/interface'

interface IState {
  params: IListBillRequest
  setParams: React.Dispatch<React.SetStateAction<IListBillRequest>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  setSortOrder: React.Dispatch<React.SetStateAction<string>>
  setSelectedEntity: (entities: string) => void
  setSelectedClient: (clients: string) => void
  setFrequency: (frequency: string) => void
}

const initialState: IState = {
  params: {
    page_size: 10,
  },
  setParams: () => null,
  setPage: () => null,
  setSortOrder: () => null,
  setSelectedEntity: () => {
    return
  },
  setSelectedClient: () => {
    return
  },
  setFrequency: () => {
    return
  },
}

export const CommitmentParamsContext = React.createContext<IState>(initialState)

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}

export const CommitmentParamsContextProvider: React.FC<Props> = ({ children }) => {
  const [params, setParams] = useState<IListBillRequest>({
    page_size: 10,
  })
  const [page, setPage] = useState<number>(1)
  const [sortOrder, setSortOrder] = React.useState<string>('')

  const setSelectedEntity = useCallback(
    (entities: string) =>
      setParams((prev) => ({
        ...prev,
        entities: entities === 'all-entities' ? undefined : entities,
      })),
    [setParams]
  )
  const setSelectedClient = useCallback(
    (clients: string) =>
      setParams((prev) => ({ ...prev, clients: clients === 'all-clients' ? undefined : clients })),
    [setParams]
  )

  const setFrequency = useCallback(
    (frequency: string) =>
      setParams((prev) => ({
        ...prev,
        frequency: frequency ? frequency : undefined,
      })),
    [setParams]
  )

  useEffect(() => {
    setParams((prev) => ({ ...prev, page }))
  }, [page])

  useEffect(() => {
    setParams((prev) => ({ ...prev, ordering: sortOrder }))
  }, [sortOrder])

  return (
    <CommitmentParamsContext.Provider
      value={{
        params,
        setParams,
        setPage,
        setSortOrder,
        setSelectedEntity,
        setSelectedClient,
        setFrequency,
      }}
    >
      {children}
    </CommitmentParamsContext.Provider>
  )
}
