import React, { useContext, useMemo } from 'react'
import {
  GridColDef,
  GridColumns,
  GridFilterModel,
  GridRowId,
  GridRowParams,
} from '@mui/x-data-grid'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import DataTable from '../DataTable'
import { useOrgListBills } from '../../../../service/hooks/orgBill'
import {
  IBill,
  IDateFilterKey,
  IDateRange,
  rejectReasonChoices,
  UserType,
} from '../../../../service/interface'
import {
  getFormattedCurrency,
  getFormattedDate,
  getLocaleDateString,
} from '../../../../utils/helpers'
import { useClientListBills } from '../../../../service/hooks/clientBill'
import Pagination from '@mui/material/Pagination'
import { IRow } from './interface'
import { Button, Chip, SvgIcon, Tooltip, Typography } from '@mui/material'
import RejectBill from '../../../modals/RejectBill'
import PreBillsTable from '../../PreBills'
import { useHistory, useLocation } from 'react-router-dom'
import { ReactComponent as CheckedIcon } from '../../../../assets/images/icons/checked_icon.svg'
import { ReactComponent as CancelIcon } from '../../../../assets/images/icons/cancel_icon.svg'
import BillWorkflowStatusChip, { statusMap } from '../../../BillWorkflowStatusChip'
import BillPaymentStatusChip from '../../../BillPaymentStatusChip'
import { CustomNoRowsOverlay } from '../../EmptyTableState'
import { EPaymentMethod, EWorkflowState, paymentMethodMap } from '../../../../service/enum'
import { BillParamsContext } from '../../../../contexts/BillParamsContext'
import { ReactComponent as WarningIcon } from '../../../../assets/images/icons/warning_icon.svg'
import { ReactComponent as CommitmentIcon } from '../../../../assets/images/icons/commitment_icon.svg'
import {
  amountFilterOperators,
  dateFilterOperators,
  processBillFilter,
  selectFilterOperators,
  stringFilterOperators,
} from '../../../../utils/filters'
import SetPageTitle from '../../../../utils/SetPageTitle'

const useListBills = {
  client: useClientListBills,
  bporg: useOrgListBills,
}

interface Props {
  type: UserType
  guid: string
  workflow_state?: string
  clientApprovalAction?: string
  cardStyle?: React.CSSProperties | undefined
  excludedColumns?: string[]
  checkboxSelection?: boolean
  isPreBill?: boolean
  selectedDateFilterKey: IDateFilterKey
  selectedDateRange: IDateRange
  selectedRows?: GridRowId[]
  tabLabel: string
  page?: string
  onApprove?: (billGuid: string, clientGuid: string, extBillId: string) => Promise<void>
  setSelectionModel?: React.Dispatch<React.SetStateAction<IRow[]>>
  setSelectedRows?: React.Dispatch<React.SetStateAction<GridRowId[]>>
}

interface RejectBillPropsSubset {
  bill: IBill | null
  open: boolean
}

const BillTable: React.FC<Props> = ({
  guid,
  type,
  workflow_state,
  clientApprovalAction,
  tabLabel,
  page,
  cardStyle = { border: 'none', boxShadow: 'none', height: '500px' },
  excludedColumns = ['Paid Date', 'Remaining Balance', 'Date approved'],
  checkboxSelection = false,
  selectedDateRange,
  selectedDateFilterKey,
  selectedRows,
  setSelectionModel,
  setSelectedRows,
  isPreBill,
  onApprove,
}) => {
  const theme = useTheme()
  const history = useHistory()
  const location = useLocation()
  const isSmallViewPort = useMediaQuery(theme.breakpoints.down('sm'))

  const pageTitle = useMemo(() => {
    return `Bills - ${tabLabel}`
  }, [tabLabel])
  const { params, setPage, setSortOrder } = useContext(BillParamsContext)

  const formatStartDate = useMemo(() => {
    if (!selectedDateRange.startDate) return
    return getLocaleDateString(selectedDateRange.startDate)
  }, [selectedDateRange.startDate])

  const formatEndDate = useMemo(() => {
    if (!selectedDateRange.endDate) return
    return getLocaleDateString(selectedDateRange.endDate)
  }, [selectedDateRange.endDate])

  const initialRejectBillProps = {
    bill: null,
    open: false,
  }
  const [rejectBillProps, setRejectBillProps] = React.useState<RejectBillPropsSubset>(
    initialRejectBillProps
  )
  const { bill: billToReject } = rejectBillProps

  const client_approval_action =
    clientApprovalAction === 'approved'
      ? clientApprovalAction + ',not_needed'
      : clientApprovalAction

  const [filterModel, setFilterModel] = React.useState<GridFilterModel>()
  const selectedFilter = useMemo(() => {
    if (!filterModel) return undefined
    return processBillFilter(filterModel)
  }, [filterModel])

  const parameters = useMemo(() => {
    const initial = {
      ...params,
      workflow_state,
      client_approval_action,
      [selectedDateFilterKey.startDate]: formatStartDate !== '-' ? formatStartDate : undefined,
      [selectedDateFilterKey.endDate]: formatEndDate !== '-' ? formatEndDate : undefined,
    }
    if (Array.isArray(selectedFilter)) {
      return {
        ...initial,
        [selectedFilter[0].field]: selectedFilter[0].value,
        [selectedFilter[1].field]: selectedFilter[1].value,
      }
    }
    if (selectedFilter && !Number.isNaN(selectedFilter?.value)) {
      return {
        ...initial,
        [selectedFilter.field]: selectedFilter?.value,
      }
    }

    return initial
  }, [
    params,
    workflow_state,
    client_approval_action,
    selectedDateFilterKey.startDate,
    selectedDateFilterKey.endDate,
    formatStartDate,
    formatEndDate,
    selectedFilter,
  ])

  const { data: listOfBills, isLoading, refetch } = useListBills[type](guid, parameters, {
    retry: (failureCount, error) => {
      if (error.response.data.code === 'not_found') {
        setPage(1)
        return false
      }
      return failureCount < 2
    },
  })

  const handleApproveClick = async (billGuid: GridRowId, guid: string, extBillId: string) => {
    if (onApprove) await onApprove(billGuid as string, guid, extBillId)
  }

  const handleRejectSuccess = async () => {
    await refetch()
  }

  const handleRejectButtonClick = (billGuid: string) => {
    const bill = listOfBills?.results.find((bill) => bill.guid === billGuid)

    if (bill) {
      setRejectBillProps({
        bill,
        open: true,
      })
    }
  }

  const handleRejectBillClose = () => setRejectBillProps(initialRejectBillProps)

  const billColumns: GridColumns = [
    {
      field: 'invoiced_on',
      headerName: 'Invoice Date',
      minWidth: 120,
      flex: 0.15,
      filterOperators: dateFilterOperators,
    },
    {
      field: 'workflow__last_approval_on',
      headerName: 'Approved Date',
      minWidth: 135,
      flex: 0.1,
      filterOperators: dateFilterOperators,
    },
    {
      field: 'workflow__rejected_on',
      headerName: 'Rejected Date',
      width: 180,
      filterOperators: dateFilterOperators,
    },
    {
      field: 'paid_on',
      headerName: 'Paid Date',
      flex: 0.45,
      filterOperators: dateFilterOperators,
    },
    {
      field: 'client__name',
      headerName: 'Client',
      width: 200,
      flex: 0.8,
      filterOperators: stringFilterOperators,
    },
    {
      field: 'vendor__name',
      headerName: 'Payee',
      width: 200,
      flex: 0.5,
      filterOperators: stringFilterOperators,
      renderCell: (params) => {
        if (params.row.vendor__name.length > 10) {
          return (
            <Tooltip title={params.row.vendor__name} placement="top-start">
              <Typography>{params.row.vendor__name}</Typography>
            </Tooltip>
          )
        } else {
          return params.row.vendor__name
        }
      },
    },
    {
      field: 'entity__name',
      headerName: 'Entity',
      width: 200,
      flex: 0.5,
      filterOperators: stringFilterOperators,
    },
    {
      field: 'workflow__state',
      type: 'singleSelect',
      headerName: 'Status',
      width: 150,
      valueOptions: () => {
        if (!workflow_state) return []
        return workflow_state.split(',').map((value) => ({
          value,
          label: statusMap[value as EWorkflowState]?.label || '',
        }))
      },
      filterOperators: selectFilterOperators,
      renderCell: (params) => <BillWorkflowStatusChip status={params.row.workflow__state} />,
    },
    {
      field: 'vendor__name',
      headerName: 'Vendor',
      width: 200,
      flex: 0.8,
      filterOperators: stringFilterOperators,
    },
    {
      field: 'workflow__client_approval_action',
      type: 'singleSelect',
      headerName: 'Approval Category',
      width: 180,
      valueOptions: [
        { value: 'not_needed', label: 'Auto-Approved' },
        { value: 'approved', label: 'Client Approved' },
      ],
      filterOperators: selectFilterOperators,
      renderCell: (params) => {
        if (params.row.workflow__client_approval_action) {
          return (
            <Chip
              label="Auto-Approved"
              sx={{
                color: '#00A6E1',
                bgcolor: 'rgba(0, 166, 225, 0.12)',
              }}
            />
          )
        } else {
          return (
            <Chip
              label="Client Approved"
              sx={{
                color: '#2B388D',
                bgcolor: 'rgba(43, 56, 141, 0.12)',
              }}
            />
          )
        }
      },
    },
    {
      field: 'payment_method',
      type: 'singleSelect',
      headerName: 'Payment Method',
      width: 145,
      valueOptions: Object.entries(paymentMethodMap).map(([value, label]) => ({
        value,
        label,
      })),
      filterOperators: selectFilterOperators,
    },
    {
      field: 'payment_status',
      type: 'singleSelect',
      headerName: 'Payment Type',
      flex: 0.4,
      renderCell: (params) => <BillPaymentStatusChip isFullyPaid={params.row.payment_status} />,
      valueOptions: [
        { value: true, label: 'Paid in Full' },
        { value: false, label: 'Partial Payment' },
      ],
      filterOperators: selectFilterOperators,
    },
    {
      field: 'amount',
      type: 'number',
      headerName: 'Amount',
      align: 'right',
      headerAlign: 'right',
      width: 100,
      flex: 0.5,
      renderCell: (params) => {
        if (params.row.is_commitment_bill) {
          return (
            <Typography>
              {params.row.amount} <SvgIcon component={CommitmentIcon} sx={{ marginTop: 1 }} />
            </Typography>
          )
        } else {
          return <Typography>{params.row.amount}</Typography>
        }
      },
      filterOperators: amountFilterOperators,
    },
    {
      field: 'workflow__reject_reason',
      type: 'singleSelect',
      valueOptions: Object.entries(rejectReasonChoices).map(([value, label]) => ({
        value,
        label,
      })),
      headerName: 'Reason',
      width: 200,
      flex: 1,
      filterOperators: selectFilterOperators,
    },
    {
      field: 'due_on',
      headerName: 'Due Date',
      width: 200,
      filterOperators: dateFilterOperators,
    },
    {
      field: 'verbal',
      headerName: 'Verbal Required',
      width: 180,
    },
    // TODO CHECK CLEARED
    // {
    //   field: 'check_status',
    //   headerName: '✅',
    //   description: 'Cleared in Bank',
    //   align: 'center',
    //   width: 90,
    //   disableColumnMenu: true,
    //   renderCell: (params) => {
    //     if (params.row.check_status === 'R') {
    //       return <Typography>✅</Typography>
    //     } else {
    //       return <Typography>{params.row.check_status}</Typography>
    //     }
    //   },
    // },
    {
      field: 'remaining_balance',
      type: 'number',
      headerAlign: 'right',
      headerName: 'Remaining Balance',
      align: 'right',
      width: 165,
      filterOperators: amountFilterOperators,
    },
    {
      field: 'synced_status',
      type: 'boolean',
      headerName: '',
      align: 'center',
      width: 40,
      renderCell: (params) => {
        if (params.row.qb_sync_status) return <></>
        return <SvgIcon component={WarningIcon} />
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      width: 300,
      cellClassName: 'actions',
      sortable: false,
      renderCell: (params) => {
        const approveClick = (event: React.MouseEvent) => {
          event.stopPropagation()
          if (type === 'bporg') {
            handleApproveClick(params.id, guid, params.row.details.ext_bill_id)
          } else {
            handleApproveClick(
              params.id,
              params.row.details.client.guid,
              params.row.details.ext_bill_id
            )
          }
        }

        if (params) {
          return (
            <>
              {params.row.is_over_limit ? (
                <Tooltip title="Amount is above your approval limit." placement="bottom-start">
                  <SvgIcon component={WarningIcon} />
                </Tooltip>
              ) : (
                <>
                  {(type === 'bporg' && params.row.details.can_be_approved) ||
                    (type === 'client' && (
                      <Button
                        sx={{ ml: '-12px', flex: 'none', wordWrap: 'break-word' }}
                        onClick={approveClick}
                      >
                        <Chip
                          icon={<SvgIcon component={CheckedIcon} />}
                          label="Approve"
                          sx={{
                            bgcolor: '#FFFF',
                            color: '#2E7D32',
                            textDecoration: 'underline',
                            fontWeight: '350',
                          }}
                        />
                      </Button>
                    ))}
                  <Button
                    type="submit"
                    onClick={() => handleRejectButtonClick(params.id as string)}
                    sx={{
                      flex: ' none',
                      wordWrap: 'break-word',
                    }}
                  >
                    <Chip
                      icon={<SvgIcon component={CancelIcon} />}
                      label="Reject"
                      sx={{
                        bgcolor: '#FFFF',
                        color: '#EB5757',
                        textDecoration: 'underline',
                        fontWeight: '350',
                        fontSize: '14px',
                      }}
                    />
                  </Button>
                </>
              )}
            </>
          )
        }
      },
    },
  ]

  const filterColumns = (excludedColumns: string[]) => {
    let filteredColumns: GridColDef[] = billColumns
    for (const columnHeader of excludedColumns) {
      filteredColumns = filteredColumns.filter(
        (billColumn) => billColumn.headerName !== columnHeader
      )
    }
    return filteredColumns
  }

  const columns = excludedColumns ? filterColumns(excludedColumns) : billColumns

  const handleRowClick = (param: GridRowParams | { row: { id: string } }) => {
    const filterRowData = listOfBills?.results.filter((row) => row.guid === param.row.id)
    const clientGuid = filterRowData ? filterRowData[0].client.guid : null
    const billDetailUrl =
      clientGuid && type === 'bporg'
        ? `/clients/${clientGuid}/${type}/bill/${param.row.id}`
        : `/bill/${param.row.id}?userType=client`

    history.push(billDetailUrl, { prevLocation: location })
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const rows: IRow[] =
    listOfBills?.results.map(
      (bill): IRow => ({
        client__guid: bill.client.guid,
        client__name: bill.client.name,
        entity__guid: bill.entity.guid,
        entity__name: bill.entity.name,
        vendor__name: bill.vendor.name,
        billAmount: bill.amount,
        amount: getFormattedCurrency(bill.amount, bill.currency),
        due_on: getFormattedDate(bill.due_on),
        workflow__verbal_approval_required: bill.workflow.verbal_approval_required
          ? 'Required'
          : 'Not Required',
        ext_bill_id: bill.ext_bill_id,
        id: bill.guid,
        details: bill,
        orgGuid: guid,
        paid_on: getFormattedDate(bill.paid_on || ''),
        remaining_balance: `$ ${(bill.remaining_balance / 100).toFixed(2)}`,
        workflow__last_approval_on: bill.workflow.last_approval_on
          ? getFormattedDate(bill.workflow.last_approval_on)
          : getFormattedDate(bill.workflow.client_approval_on || ''),
        workflow__state: bill.workflow.state,
        line_items: bill.line_items,
        invoiced_on: getFormattedDate(bill.invoiced_on || ''),
        payment_method:
          bill.payment_method === EPaymentMethod.check && bill.check_number
            ? `${paymentMethodMap[bill.payment_method]} - ${bill.check_number}`
            : paymentMethodMap[bill.payment_method],
        payment_status: bill.remaining_balance === 0,
        workflow__client_approval_action: bill.workflow.client_approval === 'not_needed',
        workflow__rejected_on: getFormattedDate(bill.workflow.rejected_on || ''),
        workflow__reject_reason:
          bill.workflow.reject_reason === 'other'
            ? `Other: ${bill.workflow.reject_other_description}`
            : rejectReasonChoices[bill.workflow.reject_reason],
        check_status: bill.check_status || '',
        qb_sync_status: bill.qb_sync_status,
        is_over_limit: bill.is_over_limit,
        is_commitment_bill: bill.is_commitment_bill,
      })
    ) || []

  if (isSmallViewPort) {
    return (
      <>
        <SetPageTitle pageTitle={pageTitle} />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination
            count={Math.ceil((listOfBills?.count || 0) / (params.page_size || 10))}
            page={params.page}
            onChange={handlePageChange}
          />
        </div>
      </>
    )
  } else if (isPreBill) {
    return (
      <div style={{ width: '100%' }}>
        {page !== 'dashboard' && <SetPageTitle pageTitle={pageTitle} />}
        <PreBillsTable
          clientGuid={type === 'client' ? guid : undefined}
          userType={type}
          clients={params.clients}
          cardStyle={cardStyle}
          prevPage="billing"
          formatStartDate={formatStartDate}
          formatEndDate={formatEndDate}
        />
      </div>
    )
  } else {
    return (
      <div style={{ width: '100%' }}>
        {page !== 'dashboard' && <SetPageTitle pageTitle={pageTitle} />}
        <DataTable
          rows={rows}
          loading={isLoading}
          columns={columns}
          components={{
            NoRowsOverlay: () => CustomNoRowsOverlay('No bills to display.'),
          }}
          cardStyle={cardStyle}
          onRowClick={handleRowClick}
          total={listOfBills?.count || 0}
          page={params.page}
          setPage={setPage}
          selectionModel={selectedRows}
          checkboxSelection={checkboxSelection}
          clickable
          setSortOrder={setSortOrder}
          isRowSelectable={(params: GridRowParams) => {
            if (params.row.is_over_limit) {
              return false
            }
            return true
          }}
          onSelectionModelChange={(ids) => {
            if (setSelectionModel && setSelectedRows) {
              const selectedIDs = new Set(ids)
              const selectedRows = rows.filter((row) => selectedIDs.has(row.id))
              setSelectionModel(selectedRows)
              setSelectedRows(ids)
            }
          }}
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={setFilterModel}
        />
        {billToReject && (
          <RejectBill
            bill={billToReject}
            open={rejectBillProps.open}
            onClose={handleRejectBillClose}
            onSuccess={handleRejectSuccess}
          />
        )}
      </div>
    )
  }
}

export default BillTable
