import React, { useEffect } from 'react'
import { Box, styled } from '@mui/material'
import { DataGrid, DataGridProps, GridSortItem, GridSortModel } from '@mui/x-data-grid'
import './styles.scss'
import clsx from 'clsx'
import { Pagination } from '@mui/material'
import Stack from '@mui/material/Stack'

export interface DataTableProps extends DataGridProps {
  cardStyle?: React.CSSProperties
  total?: number
  pageSize?: number
  clickable?: boolean
  hidePagination?: boolean
  setPage?: React.Dispatch<React.SetStateAction<number>>
  setLastRowId?: React.Dispatch<React.SetStateAction<number>>
  setSortOrder?: React.Dispatch<React.SetStateAction<string>>
  checkboxSelection?: boolean
}

interface sortOrder {
  sortModel: GridSortItem[]
}

const StyledPagination = styled(Pagination)(() => ({
  '& .MuiPaginationItem-root .MuiPaginationItem-previousNext': {
    color: '#00A6E1',
    border: '1px solid #00A6E1',
    borderRadius: '2px',
    backgroundColor: '#FFFFFF',
  },
  '& .MuiPaginationItem-root.Mui-selected': {
    color: '#00A6E1',
    border: '1px solid #00A6E1',
    borderRadius: '2px',
    backgroundColor: '#FFFFFF',
  },
  '& .MuiPaginationItem-previousNext.Mui-disabled': {
    color: '#D9D9D9',
    backgroundColor: '#F5F5F5',
    border: '1px solid #D9D9D9',
    borderRadius: '2px',
  },
}))

const StyledDataGrid = styled(DataGrid)(() => ({
  '& .super-app-theme--due': {
    color: '#D32F2F',
    fontWeight: 350,
    backgroundColor: 'rgba(211, 47, 47, 0.12)',
    '&:hover': {
      backgroundColor: 'rgba(211, 47, 47, 0.12)',
    },
    '&.Mui-selected': {
      backgroundColor: 'rgba(211, 47, 47, 0.12)',
      '&:hover': {
        backgroundColor: 'rgba(211, 47, 47, 0.12)',
      },
    },
  },
  '& .super-app-theme--blocked': {
    backgroundColor: 'rgba(239, 83, 80, 0.05)',
    '&:hover': {
      backgroundColor: 'rgba(239, 83, 80, 0.05)',
    },
    '&.Mui-selected': {
      backgroundColor: 'rgba(239, 83, 80, 0.05)',
      '&:hover': {
        backgroundColor: 'rgba(239, 83, 80, 0.05)',
      },
    },
  },
  '& .super-app-theme--ready': {
    backgroundColor: 'rgba(255, 152, 0, 0.05)',
    '&:hover': {
      backgroundColor: 'rgba(255, 152, 0, 0.05)',
    },
    '&.Mui-selected': {
      backgroundColor: 'rgba(255, 152, 0, 0.05)',
      '&:hover': {
        backgroundColor: 'rgba(255, 152, 0, 0.05)',
      },
    },
  },
  '& .prebill-selected': {
    backgroundColor: 'rgba(43, 56, 141, 0.08)',
    fontWeight: 400,
  },
  '& .prebill-client-designee': {
    backgroundColor: '#ffffe4',
  },
}))

const DataTable: React.FC<DataTableProps> = ({
  rows,
  columns,
  cardStyle = {},
  total = 0,
  clickable = false,
  page,
  pageSize = 10,
  setPage,
  setLastRowId,
  setSortOrder,
  hidePagination = false,
  checkboxSelection = false,
  ...restProps
}: DataTableProps) => {
  const totalPages = Math.ceil(total / pageSize)
  const handlePageChange = (event: React.ChangeEvent<any>, page: number) => {
    if (setLastRowId) {
      if (totalPages === page) {
        setLastRowId(5 * (page - 1))
      }
    }
    if (setPage) setPage(page)
  }

  const [queryOptions, setQueryOptions] = React.useState<sortOrder>({ sortModel: [] })
  const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setQueryOptions({ sortModel: [...sortModel] })
  }, [])

  useEffect(() => {
    if (queryOptions.sortModel.length > 0 && setSortOrder) {
      if (queryOptions.sortModel[0].sort === 'asc') {
        setSortOrder(queryOptions.sortModel[0].field)
      } else {
        const sortOrder = '-' + queryOptions.sortModel[0].field
        setSortOrder(sortOrder)
      }
    }
  }, [queryOptions, setSortOrder])

  return (
    <>
      <Box style={cardStyle}>
        <StyledDataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          checkboxSelection={checkboxSelection}
          disableExtendRowFullWidth={false}
          className={clsx(clickable && 'clickable')}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          hideFooter
          {...restProps}
        />
      </Box>
      {!hidePagination && (
        <Box py={2} display="flex" justifyContent="center">
          <Stack spacing={2}>
            <StyledPagination
              variant="outlined"
              shape="rounded"
              count={totalPages}
              onChange={handlePageChange}
              page={page}
            />
          </Stack>
        </Box>
      )}
    </>
  )
}

export default DataTable
